@import "../../styles/vars";

.gallery {
  margin: auto;
  margin-top: 32px;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    font-family: $secondary-font;
    color: $dark-text-color;
    display: flex;
    justify-content: center;
    font-weight: 200;
    font-size: 16px;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin: 0;
  }
  &__container {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 16px 0;
    &__images {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      &__button {
        width: 200px;
        height: 200px;
        border: none;
        border-radius: 8px;
        padding: 0;
        box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.5);
        cursor: pointer;
      }
    }
    &__button {
      margin: 16px;
      border: none;
      display: flex;
      justify-content: space-around;
      align-items: center;
      background: $primary-color;
      border-radius: 25px;
      color: $white-color;
      padding: 8px 24px;
      font-family: $secondary-font;
      font-weight: 400;
      font-size: 12px;
      cursor: pointer;
    }
  }
}
