.sign-in-page {
  flex: 1;
  align-self: stretch;
  background-color: #0d1a42;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sign-in-root {
  background-color: #f7f8f8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 450px;
  min-width: 550px;
  padding: 32px;
  border-radius: 16px;
}

.sign-in-form {
  width: 250px;
  text-align: center;
  white-space: pre-line;
}
