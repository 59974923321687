.page {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 90%;

  &__card {
    padding: 16px;
    margin: 16px;
    position: relative;

    &__href {
      text-align: center;
      margin-top: 16px;
    }

    &__heroContainer {
      position: relative;
      height: 150px;
    }

    &__hero {
      object-fit: cover;

      &__title {
        position: absolute;
        width: 250px;
        color: white;
        background-color: rgba(0, 0, 0, 0.3);
        padding: 8px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        bottom: 0px;
      }
    }
  }
}

.editor {
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__settings {
    position: absolute;
    top: 114px;
    right: 64px;
    z-index: 90;
  }

  &__add {
    position: absolute;
    right: 64px;
    bottom: 48px;
    z-index: 150;
  }

  p {
    text-align: justify;
    -webkit-margin-before: 0.3em;
    margin-block-start: 0.3em;
    -webkit-margin-after: 0.3em;
    margin-block-end: 0.3em;
  }
}

.toolbar {
  position: relative;

  &__container {
    position: absolute;
    top: 16px;
    left: 16px;
  }

  &__fab {
    margin-right: 16px !important;
  }
}

@media only screen and (max-width: 1300px) {
  .page {
    max-width: 95%;
  }
}
