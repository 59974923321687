@import "./styles/vars";

html,
body,
#root {
  height: 100%;
}

h1 {
  font-size: 32px;
  font-family: $primary-font;
}
h2 {
  font-size: 28px;
  font-family: $primary-font;
}

h3 {
  font-size: 24px;
  font-family: $primary-font;
}

h4 {
  font-size: 20px;
  font-family: $secondary-font;
}

h5 {
  font-size: 18px;
  font-family: $secondary-font;
}

h6 {
  font-size: 16px;
  font-family: $secondary-font;
}

p {
  font-family: $secondary-font;
}

span {
  font-family: $secondary-font;
}
