@import "../../styles/vars";

.onePlayer {
  position: relative;
  width: 500px;

  &__bg {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: $secondary-color;
  }

  &__container {
    padding-bottom: 16px;
  }

  &__filter {
    background: linear-gradient(180deg, rgba(0, 12, 51, 0.4) 0%, rgba(0, 6, 27, 1) 80%);
    width: 100%;
    height: 100%;
    min-height: 570px;
    padding-top: 80px;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    position: relative;
    gap: 0;
    padding: 8px;
    align-items: center;
    color: $white-color;
    z-index: 100;
  }
  &__firstPart {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__image {
    position: relative;

    &__profile {
      width: 300px;
    }
    &__jiff {
      width: 32px;
      position: absolute;
      right: 40px;
      top: 40px;
    }
  }
  &__name {
    display: flex;
    justify-content: center;
    font-family: $primary-font;
    font-size: 24px;
    text-transform: uppercase;
    line-height: 20px;
    margin-top: 16px;

    &__firstName {
      color: $primary-color;
      margin: 0;
      margin-right: 4px;
      font-weight: 400;
    }
    &__lastName {
      margin: 0;
      margin-left: 4px;
      font-weight: 400;
    }
  }
  &__position {
    font-family: $secondary-font;
    margin-top: 16px;
    margin-bottom: 8px;
  }
  &__rs {
    margin: 24px 0;

    &__link {
      margin: 16px;
    }
  }
  &__attributes {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
    border-bottom: solid rgba(255, 255, 255, 0.25) 1px;

    &__attribute {
      text-align: center;
      width: 80px;

      &__value {
        font-size: 44px;
        font-weight: 600;
      }
      &__unit {
        text-transform: uppercase;
        font-family: $secondary-font;
        font-weight: 200;
        letter-spacing: 4px;
      }
    }
  }
  &__infos {
    border-bottom: solid rgba(255, 255, 255, 0.25) 1px;
    width: 300px;
    padding: 16px;
    &__info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 8px 0;
      font-family: $secondary-font;
      font-size: 12px;
      letter-spacing: 2px;

      &__unit {
        text-transform: uppercase;
        font-weight: 200;
      }
      &__value {
        font-weight: 600;
      }
    }
  }
  &__bio {
    border-bottom: solid rgba(255, 255, 255, 0.25) 1px;
    width: 300px;
    padding: 16px;
    font-family: $secondary-font;
    font-size: 12px;
    font-weight: 200;
    letter-spacing: 1px;
  }
  &__career {
    margin: 16px 0;

    &__title {
      font-family: $secondary-font;
      font-size: 12px;
      letter-spacing: 2px;
      font-weight: 200;
      text-transform: uppercase;
    }
    &__text {
      font-family: $secondary-font;
      font-size: 12px;
      letter-spacing: 2px;
      font-weight: 200;
      letter-spacing: 1px;
      line-height: 20px;
    }
  }

  &__associatedArticles {
    margin-top: 48px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__container {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      max-width: 1100px;
    }
    &__title {
      font-family: $secondary-font;
      color: $secondary-color;
      display: flex;
      justify-content: center;
      font-weight: 200;
      font-size: 16px;
      letter-spacing: 2px;
      text-transform: uppercase;
      margin: 0;
      margin-top: 8px;
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    margin-top: 16px;

    &__returnLink {
      font-family: $secondary-font;
      font-size: 12px;
      text-decoration: none;
      color: $white-color;
      padding: 8px 16px;
      border-radius: 25px;
      background-color: $primary-color;
    }
  }
}
