.shopProduct {
  position: relative;
  width: 316px;
  height: 316px;
  margin: 16px;

  &__discount {
    height: 72px;
    min-width: 72px;
    background-color: #7ebae6 !important;
    border-radius: 50% !important;
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: white !important;
    font-weight: 900;
    position: absolute;
    right: -8px;
    top: -8px;
  }
  &__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 300px;
    height: 300px;

    &__priceAndDiscount {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__img {
      padding: 0;
      object-fit: cover;
      height: 180px;
    }
    &__price {
      margin-left: 8px;

      &Through {
        text-decoration: line-through;
      }
    }
    &__discountedPrice {
      margin-left: 8px;
    }
    &__description {
      font-size: 14px;
      & p {
        margin: 0;
      }
    }
  }
}
