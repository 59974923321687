@import "../../styles/vars";

.contentCard,
.contentCardNoChrome {
  padding: 0px;
  border-radius: 10px;
  overflow: clip;
  border: $dark-text-color 1px solid;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 32px;
  min-height: 64px;
  width: calc(100% - 64px);
  color: $dark-text-color;

  &__containerImg {
    overflow: clip;
    order: 1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__containerTxt {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    padding: 32px;

    &__title {
      text-transform: uppercase;
      font-size: 32px;

      margin: 8px 0;
    }
  }
  &__image {
    border-radius: 15px;
    object-fit: cover;
    object-position: 50% 50%;
    width: 450px;
    height: 450px;
  }

  &__cta {
    margin-top: 8px;
  }
}

.noChrome {
  border-radius: 0px;
  box-shadow: none;
  border: none;
  margin: 0;
  width: 100%;
  margin-bottom: 16px;
}

.noChrome__containerTxt {
  padding: 8px 32px;
}

.imageLeft {
  order: 0;
}

.htmlContent {
  width: 100%;
}

@media only screen and (max-width: 990px) {
  .contentCard {
    flex-direction: column;
    margin: 8px 0;
    &__containerImg {
      max-width: 100%;
    }
    &__containerTxt {
      align-items: center;
      padding: 8px 24px 16px 24px;

      &__title {
        font-size: 24px;
      }
    }
  }
  .contentCard,
  .contentCardNoChrome {
    box-sizing: border-box;
    width: 100%;
    &__image {
      max-width: 100vw;
      max-height: 100vw;
    }
  }

  .imageLeft {
    order: 1;
  }
}
