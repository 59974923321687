@import "../../styles/vars";

.helloAssoModule {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 32px;

  &__title {
    text-transform: uppercase;
    font-size: 32px;
    margin: 8px 0;
  }

  &__iframe {
    width: 1000px;
    height: 1050px;
    border: none;
  }
}

@media only screen and (max-width: 990px) {
  .helloAssoModule {
    &__iframe {
      width: 300px;
      height: 500px;
    }
  }
}
