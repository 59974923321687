@import "../../styles/vars";

.newsSlider {
  max-width: 1100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0;

  &__title {
    text-transform: uppercase;
    font-size: 32px;
    margin: 8px 0;
    color: $dark-text-color;
  }

  &__containerSlide {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1100px;
    margin-bottom: 8px;
  }
}

.article {
  display: flex;
  width: 340px;
  height: 210px;
  position: relative;
  margin: 8px;
  transition: transform 300ms ease-out;

  &:hover {
    transform: translate(0px, 0px) scale(1.05, 1.05);
  }

  &__picture {
    border-radius: 10px;
    width: 340px;
    height: 210px;
    object-fit: cover;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5);
  }

  &__filter {
    text-decoration: none;
    background: $filter-gradient;
    border-radius: 10px;
    color: $white-color;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    // padding: 16px;
    z-index: 10;
  }
  &__category {
    position: absolute;
    top: 16px;
    left: 16px;
    border: 1px solid #ffffff;
    border-radius: 16px;
    background: rgba(0, 0, 0, 0.4);
    font-family: $secondary-font;
    font-weight: 700;
    font-size: 10px;
    padding: 3px 13px;
    margin: 0;
  }

  &__title {
    width: 205px;
    position: absolute;
    bottom: 16px;
    left: 16px;
    margin: 0;
    padding-right: 8px;
    color: $white-color;
    font-family: $primary-font;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  &__date {
    align-self: flex-end;
    justify-self: flex-end;

    position: absolute;
    bottom: 16px;
    right: 16px;
    margin: 0;
    font-family: $secondary-font;
    font-weight: 400;
    font-size: 10px;
  }
}
