@import "../../styles/vars";

.hero {
  &__container {
    position: relative;
    width: 100%;
    &__bg {
      height: 650px;
      width: 100%;
      object-fit: cover;
      object-position: top;
    }
  }
  &__box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 40;
    font-size: 32px;
    line-height: 1;
    color: white;
    text-transform: uppercase;
    text-shadow: 3px 4px 8px rgba(0, 0, 0, 0.8);
    font-weight: 400;
  }

  &__boxT {
    display: flex;
    flex-direction: row;
    z-index: 40;
    text-transform: uppercase;
    text-shadow: 3px 4px 8px rgba(0, 0, 0, 0.8);
    color: white;
    margin: 0 16px 32px 32px;
    &__title {
      font-family: $primary-font;
      font-size: 48px;
      font-weight: 600;
      margin: 0;
    }
  }
}

@media only screen and (max-width: 768px) {
  .hero {
    &__container {
      &__bg {
        margin-top: 55px;
        height: 400px;
      }
    }

    &__boxT {
      flex-direction: column;
      &__title {
        font-size: 40px;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .heroHome {
    margin-top: 55px;
  }
}

@media only screen and (max-height: 1000px) {
  .hero {
    margin-top: 55px;
    &__container {
      &__bg {
        height: 400px;
      }
    }
  }
}
