@import "../../styles/vars";

.linkButton {
  border-radius: 25px;
  text-decoration: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 24px;
  font-family: $secondary-font;
  font-weight: 600;
  font-size: 14px;
  width: auto;
  margin: 8px 2px;
  border: none;
  cursor: pointer;
  white-space: nowrap;

  &__primary {
    background-color: $primary-color;
    color: $white-color;
    &:hover {
      background-color: $secondary-color;
    }
  }
  &__secondary {
    background-color: $secondary-color;
    color: $white-color;
    border: solid 1px $secondary-color;
  }
  &__white {
    background-color: $white-color;
    color: $secondary-color;
    &:hover {
      background-color: $primary-color;
      color: $white-color;
    }
  }
  &__whiteWithBorder {
    background-color: $white-color;
    color: $secondary-color;
    border: solid 1px $secondary-color;
    &:hover {
      background-color: $secondary-color;
      color: $white-color;
      border: solid 1px $secondary-color;
    }
  }
  &__label {
    line-height: 18px;
  }
  &__icon {
    margin-left: 10px;
  }
}

.linkWithChildren {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .linkButton {
    font-size: 12px;
    padding: 4px 10px;

    &__icon {
      margin-left: 8px;
    }
  }
}
