@import "../../styles/vars";

.match {
  height: 340px;
  margin: 16px;
  border-radius: 10px;
  display: flex;
  justify-content: center;

  &__root {
    width: 280px;
    position: relative;
  }

  &__bg {
    object-fit: cover;
    width: 280px;
    height: 340px;
    border-radius: 10px;
  }

  &__filter {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: radial-gradient(50% 50% at 50% 50%, rgba(204, 233, 255, 0.304) 0%, rgba(0, 110, 212, 0.4) 100%),
      linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 19, 85, 0.8) 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 40;
  }

  &__header {
    position: absolute;
    top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;

    &__infos {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      background-color: $white-color;
      max-height: 32px;
      padding: 0px 8px;
      border-radius: 9px;
      height: 35px;
      font-family: $secondary-font;
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 8px;
      margin-top: -28px;
      p {
        margin: 1px;
      }
    }
    &__date {
      margin: 0;
      font-family: $secondary-font;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 8px;
      color: $white-color;
      text-transform: uppercase;
    }
    &__time {
      margin-top: 8px;
      font-family: $secondary-font;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 8px;
      color: $white-color;
      text-transform: uppercase;
      text-align: right;
    }
  }
  &__logos {
    position: absolute;
    top: 80px;
    display: flex;
    justify-content: space-between;
    width: 70%;
    margin: 32px 0;
    &__home {
      width: 83px;
      height: 83px;
      object-fit: contain;
    }
    &__away {
      width: 83px;
      height: 83px;
      object-fit: contain;
    }
  }
  &__score {
    font-family: $secondary-font;
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 8px;
    color: $white-color;
    margin: 32px 0;
    position: absolute;
    top: 180px;
  }
  &__teams {
    position: absolute;
    bottom: 48px;
    font-family: $secondary-font;
    font-style: normal;
    font-weight: 800;
    color: $white-color;
    text-transform: uppercase;
    margin: 8px 10px;
    text-align: center;
    &__home {
      margin: 0;
      font-size: 16px;
      line-height: 20px;
    }
    &__away {
      margin: 0;
      font-size: 16px;
      line-height: 20px;
    }
  }
  &__button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: $white-color;
    color: $secondary-color;
    width: 188px;
    height: 29px;
    padding: 0px 15px;
    border-radius: 27px;
    position: relative;
    border: none;
    margin-bottom: 16px;

    &__arrow {
      color: $secondary-color;
      position: absolute;
      right: 16px;
    }
  }
  &__footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    justify-content: flex-end;

    &__content {
      display: flex;
      justify-content: space-between;
      padding: 0 8px;
    }
  }
}
