@import "../../styles/vars";

.faq {
  padding: 32px;
  color: $dark-text-color;

  &__title {
    text-align: center;
    margin-bottom: 16px !important;
  }

  &__darkText {
    color: $dark-text-color;
  }
}

@media only screen and (max-width: 768px) {
  .faq {
    padding: 16px;
  }
}
