.banner {
  width: auto;
  position: relative;
  height: 420px;
  margin-bottom: 8px;

  &__fullWidth {
    height: 100%;
    width: 100%;
  }

  &__picture {
    border-radius: 10px;
    height: 420px;
    width: 100%;
    max-width: 700px;
    object-fit: cover;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5);

    &__fullWidth {
      height: 100%;
      max-width: 100%;
      border-radius: 0;
      box-shadow: none;
      object-fit: contain;
    }
  }

  &__overlay {
    position: absolute;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 31.77%, rgba(0, 19, 85, 0.6) 100%);
    border-radius: 10px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
  }

  &__contentContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 24px;
    z-index: 10;
  }

  &__title {
    font-size: 32px;
    line-height: 1;
    color: white;
    text-transform: uppercase;
    font-weight: 600;
    text-align: right;
  }
}

.noChrome {
  border-radius: 0px;
  box-shadow: unset;
  max-width: unset;
}

@media only screen and (max-width: 768px) {
  .banner {
    &__title {
      font-size: 24px;
      text-align: right;
    }
  }
}
