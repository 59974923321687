.galleryModal {
  display: flex;
  justify-content: center;
  align-items: center;

  &__container {
    width: 90%;
    background-color: black;
    position: relative;
    &__cancelIcon {
      font-size: 32px;
      z-index: 42;
      color: white;
      position: absolute;
      bottom: 3%;
      right: 3%;
      cursor: pointer;
    }
  }
}

.videoModal {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 32px;
  &__iframe {
    width: 90%;
    height: 100%;
    border: 0;
  }
}

@media only screen and (max-width: 990px) {
  .videoModal {
    padding: 16px;
    &__iframe {
      height: 45%;
    }
  }
}

@media only screen and (max-width: 768px) {
  .videoModal {
    padding: 8px;
    &__iframe {
      height: 33%;
    }
  }
}
