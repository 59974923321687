@import "../../styles/vars";
.video {
  &__card {
    height: 197px;
    position: relative;
    cursor: pointer;
    border: none;
    padding: 0;
    margin: 8px;
    transition: transform 300ms ease-out;
    border-radius: 8px;

    &:hover {
      transform: translate(0px, 0px) scale(1.05, 1.05);
    }

    &__poster {
      height: 197px;
      width: 350px;
      border-radius: 8px;
      box-shadow: (0px 4px 4px rgba(0, 0, 0, 0.25));
    }

    &__overlay {
      position: absolute;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 31.77%, rgba(0, 19, 85, 0.6) 100%);
      border-radius: 8px;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 5;
    }

    &__content {
      position: relative;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding: 0 20px 20px 20px;
    }

    &__playContainer {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 6;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__body {
      color: $white-color;
      bottom: 0;
      border-radius: 0 0 8px 8px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: stretch;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 10;

      &__title {
        margin: 0;
        color: $white-color;
        font-size: 16px;
        line-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-family: $primary-font;
        font-weight: 600;
        text-align: left;
      }
    }

    &__play {
      width: 32px;
    }
  }
}

@media only screen and (max-width: 990px) {
  .video {
    &__card {
      height: 169px;
      &__poster {
        height: 169px;
        width: 300px;
      }
    }
  }

  .videoModal {
    padding: 16px;
    &__iframe {
      height: 45%;
    }
  }
}

@media only screen and (max-width: 768px) {
  .videoModal {
    padding: 8px;
    &__iframe {
      height: 33%;
    }
  }
}
