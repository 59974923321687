.zipLoader__title {
  width: 600px;
  color: #101235;
  font-weight: 600;
  text-align: center;
  font-size: 24px;
  margin: 24px 16px 8px 16px;
}

@media only screen and (max-width: 768px) {
  .zipLoader__title {
    width: 300px;
    font-size: 16px;
    margin: 16px 8px 0 8px;
  }
}
