// colors
$primary-color: #7ebae6;
$secondary-color: #00186c;
$black-color: #00092b;
$white-color: #fff;
$dark-text-color: #00186c;

// gradient
$blackToNavy-gradient: linear-gradient(180deg, #00092b 0%, #011355 100%);
$ciel-gradient: linear-gradient(180deg, #7ebae6 0%, #c1e0f7 100%);
$filter-gradient: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(13, 26, 66, 0.5) 100%);

// fonts
$primary-font: "Familjen Grotesk";
$secondary-font: "Manrope";
$third-font: "Futura Std";
