@import "../../styles/vars";

.cardsGrid {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 32px;

  &__title {
    text-transform: uppercase;
    font-size: 32px;
    margin: 8px 0;
    color: $dark-text-color;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    width: 1000px;

    &__3columns {
      justify-content: center;
    }
  }

  &__oneCard {
    width: 450px;
    height: 200px;
    position: relative;
    margin-bottom: 8px;
    margin: 16px;

    &__3columns {
      width: 280px;
    }

    &__link {
      &:hover {
        transform: translate(0px, 0px) scale(1.05, 1.05);
      }
    }

    &__picture {
      border-radius: 10px;
      width: 450px;
      height: 200px;
      object-fit: cover;
      box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5);

      &__3columns {
        width: 280px;
      }
    }

    &__overlay {
      position: absolute;
      border-radius: 10px;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 5;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(13, 26, 66, 0.5) 100%);
    }

    &__contentContainer {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 8px;
      z-index: 10;
    }

    &__title {
      position: absolute;
      font-size: 24px;
      line-height: 1;
      color: white;
      text-transform: uppercase;
      font-weight: 600;
      text-align: right;
      margin: 0;
    }
  }
}

@media only screen and (max-width: 768px) {
  .cardsGrid {
    &__container {
      width: 332px;
    }

    &__oneCard {
      width: 300px;
      height: 135px;

      &__picture {
        width: 300px;
        height: 135px;
      }
    }
  }
}
