@import "../../styles/vars";

.shopProductSlider {
  margin: auto;
  margin-top: 32px;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    font-family: $secondary-font;
    color: $dark-text-color;
    display: flex;
    justify-content: center;
    font-weight: 200;
    font-size: 16px;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin: 0;
  }
  &__container {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
